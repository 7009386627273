
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
  html {
    scroll-behavior: smooth;
  }
*{
    font-family: 'Varela Round', sans-serif;
}
.title{
    font-family: 'Varela Round', sans-serif;
}
.number-font{
    font-family: 'Monoton', sans-serif;
}
.cii-font{
    font-family: 'Russo One', sans-serif;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}



.shadow{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.child{
    filter: grayscale(100%);
}
.swiper-pagination{
    position: relative!important;
    margin: 20px 0px 0px 0px;
}
.swiper-pagination-bullet-active{
    background-color: #033C89!important;
}

.desktop{
    display: none;
}
.gallery{
    columns: 100px 3;
    column-gap: 20px;
}
.gallery-item{

}
.element{
    right: 50%;
    top:50%;
    z-index: 10!important;
}
.blackgrey{
    background-color: #033C89;
    z-index: 5!important;
}
.pulse-animation {
    display: block;
    border-radius: 50%;
    /* background: #ff8717; */
    cursor: pointer;
    position: absolute;

    animation: pulse 2s infinite;
    float: left;
  }
  /* .backgrond{
    background: url(../Asset/Image/Home/AIG.png);
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%;
  } */

  
  .pulse-animation:hover {
    animation: none;
  }
  
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 30px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 30px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }

  .border-yellow-header{
    border-right: 20px solid #c29729;
    border-radius:0px 70px 10px 0px;
  }

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
  }
  .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #c29729;
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }

  .model-popup {
    position: fixed;
    background:#033C89;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  #scrolling {
    display: none;
  }
  .desktop-heading{
    display: none!important;
  }

  textarea:focus, input:focus{
    outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.buttonloader {
  width: 30px!important;
  height: 30px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.buttonloader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}