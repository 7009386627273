@media only screen and (min-width: 1024px) and (max-width: 1280px) {}

@media only screen and (min-width: 900px) and (max-width: 1023px) {

    .desktop-heading{
        display: block!important;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
    .desktop-heading{
        display: block!important;
    }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
    .container{
        width:100%;
    }
    .desktop{
        display: block!important;
    }
    .gallery{
        columns: 100px 2!important;
    }
    .desktop-heading{
        display: block!important;
    }
}


@media only screen and (min-width: 200px) and (max-width: 640px) {
    .container{
        width:100%!important;
        margin:0px!important;
        max-width: 100%!important;
    }
    .desktop{
        display: block!important;
    }
    .gallery{
        columns: 100px 1!important;
    }
    .desktop-heading{
        display: block!important;
    }
    
}